import FeatureGates, { FeatureGateEnvironment } from '@atlaskit/feature-gate-js-client';

import { useAtlasKitAnalytics } from '@townsquare/analytics';
import { featureFlagApiKey, isStaging } from '@townsquare/feature-flags';

import { COHORT_PARAMETER, Cohort, DEFAULT_VALID_EXPERIMENT_COHORTS, NOT_ENROLLED, VARIATION } from './cohorts';
import { type ExperimentNames } from './experiment-keys';

const getFeatureGateEnv = () => (isStaging() ? FeatureGateEnvironment.Staging : FeatureGateEnvironment.Production);

export const featureGatesOptions = {
  targetApp: 'atlas_web', // https://hello.atlassian.net.atl.zbizdev.zengel.myshn.net/wiki/spaces/MEASURE/pages/2955970231/How-to+Use+TargetApps+in+Statsig
  apiKey: featureFlagApiKey,
  environment: getFeatureGateEnv(),
};

export const useIsExperimentEnabled = (
  experimentName: ExperimentNames,
  variantKey: Cohort = VARIATION,
  validExperimentCohorts: Cohort[] = DEFAULT_VALID_EXPERIMENT_COHORTS,
  fireExperimentExposure = true,
) => {
  if (FeatureGates.initializeCalled()) {
    const cohort = FeatureGates.getExperimentValue(experimentName, COHORT_PARAMETER, NOT_ENROLLED, {
      fireExperimentExposure,
    }) as Cohort;
    const isValid = typeof cohort === 'string' && validExperimentCohorts.includes(cohort);
    return (isValid ? cohort : NOT_ENROLLED) === variantKey;
  }

  return false;
};

export const useIsExperimentEnabledWithoutExposure = (
  experimentName: ExperimentNames,
  variantKey: Cohort = VARIATION,
  validExperimentCohorts: Cohort[] = DEFAULT_VALID_EXPERIMENT_COHORTS,
) => useIsExperimentEnabled(experimentName, variantKey, validExperimentCohorts, false);

export const useExperimentCohort = (experimentKey: string, defaultCohort: string, fireExperimentExposure?: boolean) => {
  const { sendOperationalEvent } = useAtlasKitAnalytics();

  if (FeatureGates.initializeCalled()) {
    try {
      return FeatureGates.getExperimentValue(experimentKey, COHORT_PARAMETER, defaultCohort, {
        fireExperimentExposure,
      });
    } catch (error: any) {
      sendOperationalEvent({
        action: 'failed',
        actionSubject: 'getFeatureGatesExperimentCohort',
        attributes: {
          error: error ? error.message : 'Could not get experiment cohort value from Feature Gate client',
        },
      });
      return defaultCohort;
    }
  }
  return defaultCohort;
};

export const manuallyLogExperimentExposure = (experimentName: ExperimentNames) =>
  FeatureGates.manuallyLogExperimentExposure(experimentName);

export * from './experiment-keys';
export { cohorts, COHORT_PARAMETER } from './cohorts';
